import React from 'react'
import logofooter from '../src/assets/logo_footer.svg';
function Footer() {
  return (
    <>
     <div className=' text-center text-sm pb-12 px-3'> 
        <img src={logofooter} alt='' className='mx-auto mb-5' />
        <p className='font-bold mb-6 text-md lg:text-sm'>Copyright © 2024 Fidelidade. Todos os direitos reservados.</p>
        <p className='mb-4'>
 (1) Até ao limite do capital contratado para cada cobertura.<br className='hidden lg:block' />
(2) Consulta de observação e exames nas condições em vigor.<br className='hidden lg:block' />
Esta informação não dispensa a consulta da informação pré-contratual e contratual legalmente exigida.</p>

<p  className='mb-4'>Fidelidade - Companhia de Seguros, S.A. - NIPC e Matrícula 500 918 880, na CRC Lisboa - Sede: Largo do Calhariz, 30 1249-001 Lisboa – Portugal - Capital Social EUR 509.263.524</p>

<p  className='mb-4'>Apoio ao Cliente: Dias úteis das 9h às 20h. Telefone: 217 948 701 - Chamada para a rede fixa nacional. Email: <a href="mailto:apoiocliente@fidelidade.pt.">apoiocliente@fidelidade.pt.</a> <a href="www.fidelidade.pt" target='_blank'>www.fidelidade.pt</a></p>



        </div>   
    </>
  )
}

export default Footer