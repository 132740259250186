import React, { useEffect, useRef, useState } from 'react';

import './App.css';
import cat from '../src/assets/cat.png'
import dog from '../src/assets/dog.png';
import fromicon from '../src/assets/form-icon.svg';
import logo from '../src/assets/logo.svg';
import nameicon from '../src/assets/name.svg';
import phoneicon2 from '../src/assets/phone.svg';
import zipicon from '../src/assets/zip.svg';
import nificon from '../src/assets/nif.svg';
import emailicon from '../src/assets/email.svg';
import arrowcta from '../src/assets/arrow-cta.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 

import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import Fidelidadepet from './Fidelidadepet';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Footer from './Footer';
 
 
 

function App() {

 
  
 

  const [countdown, setCountdown] = useState(15); 
  const targetUrl = 'https://www.fixo.pt/fixo/?utm_source=referral&utm_medium=afiliacao&utm_campaign=fid_pontual_fixo_referral_afiliacao_lp_pets_typ&utm_content=produto'; // URL de destino
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  
 

  
 
  

  
 
 
 

 
  
  

 
  
  const [isSubmitting, setIsSubmitting] = useState(false); // Adicionar este estado

  
  const [formstep, setFormstep] = useState(1); // Correto
  const [errorMessage, setErrorMessage] = useState('');
  const [showAgeQuestion, setShowAgeQuestion] = useState(false);
  const [showMoreText, setShowMoreText] = useState(false); // Toggle para termos e condições
  const [formdata, setFormdata] = useState({
    pettype: '',
    agerange: '',
    petage: '',
    petname: '',
    name: '',
    email: '',
    nif: '',
    zip: '',
    phone: ''
  });

  const getUUID = () => {
    if (crypto && typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID();
    } else {
      // Simple UUID generator (not cryptographically secure)
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
  };

  function getPublisherName() {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get("publisher")?.toLowerCase() || "N/A";
  }

  // Inicialize o estado diretamente com o valor da query string
  const [publishername, setPublisher] = useState(getPublisherName());

  // useEffect para definir o publisher após o carregamento da página
  useEffect(() => {
    const publisherValue = getPublisherName();
    setPublisher(publisherValue);
    console.log("Publisher:", publisherValue);
  }, []); // Executa apenas uma vez após o carregamento da página

  let sessionValue = sessionStorage.getItem('user_session');
  let sessionValueGuid = sessionStorage.getItem('user_sessionGuid');
  
  // Verifica e define `user_session`
  if (!sessionValue) {
    sessionValue = `session_${new Date().getTime()}`;  
    sessionStorage.setItem('user_session', sessionValue);  
  }
  
  // Verifica e define `user_sessionGuid` (GUID)
  if (!sessionValueGuid) {
    sessionValueGuid = getUUID(); // Gera um GUID único
    sessionStorage.setItem('user_sessionGuid', sessionValueGuid); // Armazena o GUID no sessionStorage
  }
  
  const isFormValid = () => {
    const petNameFilled = formdata.petname.trim() !== ''; // Verifica se o nome do pet está preenchido
    const petAgeFilled = formdata.petage.trim() !== ''; // Verifica se a idade do pet está preenchida
  
    if (!petNameFilled) {
      petNameimp.current.focus();
      return false;
    }
    if (!petNameFilled || !petAgeFilled) {
      setErrorMessage('Por favor, preencha o nome e idade antes de continuar.'); // Define a mensagem de erro

      return false; // Retorna falso se não estiver válido
    }
  
    setErrorMessage(''); // Limpa a mensagem de erro se ambos os campos estiverem preenchidos
    return true; // Retorna verdadeiro se ambos estiverem preenchidos
  };
  
  
 
  const nomeinp = useRef();
  const emailinp = useRef();
  const nifinp = useRef();
  const zipinp = useRef();
  const mobileinp = useRef();
  const petNameimp = useRef();


  const updatepettype = (pettype) => {
    let oldData = { ...formdata };
    oldData["pettype"] = pettype;
    setFormdata(oldData);
    setShowAgeQuestion(true);
  };

  const handleSliderChange = (event, newValue) => {
    let oldData = { ...formdata };
    oldData["agerange"] = getagerange(newValue);
    oldData["petage"] = getpetage(newValue);
    setFormdata(oldData);
  };

  const getagerange = (newValue) => {
    switch (newValue) {
      case 1:
        return <>Entre <span className='text-themered text-5xl px-2'> 2 </span> e <span className='text-themered text-5xl px-2'> 6 </span> meses</>;
      case 2:
        return <>Entre <span className='text-themered text-5xl px-2'> 6 </span> meses e <span className='text-themered text-5xl px-2'> 1,5 </span> anos</>;
      case 3:
        return <>Entre <span className='text-themered text-5xl px-2'> 1,5 </span> anos e <span className='text-themered text-5xl px-2'> 2 </span> anos</>;
      case 4:
        return <>Entre <span className='text-themered text-5xl px-2'> 2 </span> anos e <span className='text-themered text-5xl px-2'> 4 </span> anos</>;
      case 5:
        return <>Entre <span className='text-themered text-5xl px-2'> 4 </span> anos e <span className='text-themered text-5xl px-2'> 7 </span> anos</>;
      default:
        return <>Entre <span className='text-themered text-5xl px-2'> 7 </span> anos e <span className='text-themered text-5xl px-2'> 8 </span> anos</>;
    }
  };

  const getpetage = (newValue) => {
    switch (newValue) {
      case 1: return "Entre 2 e 6 meses";
      case 2: return "Entre 6 meses e 1,5 anos";
      case 3: return "Entre 1,5 anos e 2 anos";
      case 4: return "Entre 2 anos e 4 anos";
      case 5: return "Entre 4 anos e 7 anos";
      default: return "Entre 7 anos e 8 anos";
    }
  };

  const validateform = (e) => {
    let oldData = { ...formdata };
    let inp = e.target.name;
    let inpvalue = e.target.value.slice(0, e.target.maxLength);
    oldData[inp] = inpvalue;
    setFormdata(oldData);
  };

  const validatezip = (e) => {
    let value = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = value.replace(/\D/g, "");
    if (inputNumbersOnly.length > 8) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 8);
    }
    const splits = inputNumbersOnly.match(/.{1,4}/g);
    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join("-");
    }
    let oldData = { ...formdata };
    let inp = e.target.name;
    oldData[inp] = spacedNumber;
    setFormdata(oldData);
  };

  const checkempty = (inp) => {
    if (inp.current.value === '') {
      inp.current.style.backgroundColor = "white";
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    } else {
      inp.current.classList.remove("shadowinput");
      return true;
    }
  };

  const checkemail = (inp) => {
    const errorMessage = document.getElementById('emaillbl');
    var emailregex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!inp.current || !inp.current.value) {
    
      return false;
    }
    
    if (!emailregex.test(inp.current.value)) {
      errorMessage.textContent = 'Introduza um endereço de e-mail válido.';
      inp.current.style.backgroundColor = "white";
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    } else {
      errorMessage.textContent = '';
      inp.current.classList.remove("shadowinput");
      return true;
    }
  };
  

  const checkmobile = (inp, length) => {
    if (!inp.current || !inp.current.value) {
      return false;
    }
    
    const errorMessage = document.getElementById('mobilelbl');
    let value = inp.current.value;
    value = value.replace(/\D/g, ''); // Remove non-digit characters
    let iserror = false;
  
    // Check if the number is 9 digits long and starts with the allowed prefixes
    if (value.length === 9 && value[0] === '9' && ['1', '2', '3', '6'].includes(value[1])) {
      iserror = false;
      errorMessage.textContent = '';
    } else if (value.length === 9 && (value[0] !== '9' || !['1', '2', '3', '6'].includes(value[1]))) {
      errorMessage.textContent = '91, 92, 93 ou 96.';
      iserror = true;
    } else if (value.length < 9) {
      errorMessage.textContent = '9 dígitos.';
      iserror = true;
    }
  
    if (iserror) {
      inp.current.style.backgroundColor = "white";
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    } else {
      inp.current.classList.remove("shadowinput");
      return true;
    }
  };
  
  const checkpostalcode = (inp, length) => {
    const errorMessage = document.getElementById("ziplbl");
    let iserror = false;
    if (inp.current.value.length !== length) {
      errorMessage.textContent = 'O código postal deve ser exactamente 7 dígitos.';
      iserror = true;
    } else {
      iserror = false;
      errorMessage.textContent = '';
    }
    if (iserror) {
      inp.current.style.backgroundColor = "white";
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    } else {
      inp.current.classList.remove("shadowinput");
      return true;
    }
  };

  const checknif = (inp, length) => {
    const errorMessage = document.getElementById("niflbl");
    let iserror = false;
    const nif = inp.current.value;
  
    // Verifica o comprimento do NIF
    if (nif.length != length) {
      errorMessage.textContent = 'O NIF deve ter exatamente 9 dígitos.';
      iserror = true;
    } else if (!validateNIF(nif)) {
      // Se o comprimento está correto, valida o NIF com as regras adicionais
      errorMessage.textContent = 'O NIF fornecido é inválido.';
      iserror = true;
    } else {
      iserror = false;
      errorMessage.textContent = '';
    }
  
    if (iserror) {
      inp.current.style.backgroundColor = "white";
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    } else {
      inp.current.classList.remove("shadowinput");
      return true;
    }
  }
  
  // Função auxiliar para validar o NIF (integrada dentro do checknif)
  function validateNIF(nif) {
    // Verifica se o primeiro ou os dois primeiros dígitos são válidos
    if (
      !['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) &&
      !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2))
    ) {
      return false;
    }
  
    // Cálculo do total com base nos primeiros 8 dígitos
    let total =
      nif[0] * 9 +
      nif[1] * 8 +
      nif[2] * 7 +
      nif[3] * 6 +
      nif[4] * 5 +
      nif[5] * 4 +
      nif[6] * 3 +
      nif[7] * 2;
  
    // Calcula o módulo 11
    let modulo11 = total - parseInt(total / 11) * 11;
    let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;
  
    // Verifica se o último dígito corresponde ao comparador
    return nif[8] == comparador;
  }
  
  let source_campaign = 410; 

  const submitform = () => {
 
   

    
    if (formstep == 1) {

 
      sendDataToGoogleSheet({
        fromwhere: "step1" ,
          petage : formdata.petage,
          observations: "",
          petname: formdata.petname,
          pettype: formdata.pettype,
          publisher:   publishername
      });
     
    }

    if (formstep === 2) {
      if (
        checkempty(nomeinp) &&
        checkemail(emailinp) &&
        checknif(nifinp, 9) &&
        checkpostalcode(zipinp, 8) &&
        checkmobile(mobileinp, 9)
      ) {
 
        sendDataToGoogleSheet({
          fromwhere : "step3",
          name: nomeinp.current.value,
          email: emailinp.current.value,
          phone: mobileinp.current.value,
          vat: nifinp.current.value,
          petage : formdata.petage,
          observations:  "Pet Name:"+formdata.petname +" Pet Type:"+formdata.pettype + " Pet Age:" + formdata.petage,
          petname: formdata.petname,
          pettype: formdata.pettype,
          publisher:  publishername,
          postalCode: zipinp.current.value
        }); 
        senddata();
 


      

        const timer = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown === 1) {
              window.location.href = targetUrl; // Redireciona após a contagem regressiva
              clearInterval(timer); // Para o timer após o redirecionamento
            }
            return prevCountdown - 1;
          });
        }, 1000);
      }
    }
  };




  const senddata = async () => {
    if (isSubmitting) return; // Bloqueia se já estiver enviando dados


    let sessionValueGuid = sessionStorage.getItem('user_sessionGuid');
  
    // Se a variável for nula ou indefinida, gere um novo GUID
    if (!sessionValueGuid) {
      sessionValueGuid = getUUID(); // Gera um novo GUID
      sessionStorage.setItem('user_sessionGuid', sessionValueGuid); // Salva no sessionStorage
    }
  
    // Se o nome for "Affiliate", dispara a lógica específica
    if (formdata.name.trim().toLowerCase() === "affiliate" || formdata.name.trim().toLowerCase().includes("test")) {

      setFormstep(3);
      SetPixel();
      return; // Não prossegue com o envio de dados
    }
  
    const data = {
      nif: formdata.nif,
      name: formdata.name,
      email: formdata.email,
      phone: formdata.phone,
      zip: formdata.zip,
      argan: "",
      petname: formdata.petname,
      licenseplate: "",
      observations:
        "Pet Name:" +
        formdata.petname +
        " Pet Type:" +
        formdata.pettype +
        " Pet Age:" +
        formdata.petage,
      source: source_campaign,
      scheduletype: 0,
      isClient: true,
      family: 0,
        uuid: sessionValueGuid,
      birthdate: "",
      date: new Date().toISOString(),
    };
  
    const response = await fetch(
      "https://fidelidadeapi.quickflowai.com/Leads/add",
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  
    const result = await response.json();
  
    if (response.status == 200) {

      setIsSubmitting(true);
      if (result.value == "Duplicate lead") {
        setFormstep(5);
      } else {
        const today = new Date();
        const day = today.getDay();
        if (day >= 1 && day <= 4) {
          setFormstep(3);
          SetPixel();
        } else {
          setFormstep(4);
          SetPixel();
        }
      }
    }
  };




function SetPixel() {

/*
  if (Math.random() > 0.2) return;
  
  if (!sessionValueGuid) {
    sessionValueGuid = getUUID(); // Gera um GUID único
    sessionStorage.setItem('user_sessionGuid', sessionValueGuid); // Armazena o GUID no sessionStorage
  }

  const img = document.createElement("img");
  img.src = `https://action.metaffiliation.com/trk.php?mclic=G5130B11011&argann=${sessionValueGuid}`;

  img.height = 1;
  img.width = 1;
  img.style.display = "none";
  document.body.appendChild(img);
 ~*/
 
}



 
  const sendDataToGoogleSheet = async ({
    fromwhere = "",
    name = "",
    email = "",
    phone = "",
    vat = "",
    postalCode = "",
    observations = "N/A",
    petname = "N/A",
    pettype = "N/A",
    petage = "N/A",
    publisher = "N/A"
  } = {}) => {
    // URLs dos scripts do Google Apps
    const scriptUrl1 = 'https://script.google.com/macros/s/AKfycbzn-Mbyx3RixBni7507Zpsibl1dqJHC21Dlu9tPDg_TS0lk8p-q11gyvSPsqx4rIzfH/exec';
    const scriptUrl2 = 'https://script.google.com/macros/s/AKfycbyqVB9saGMH1A5SgI3zrBUjTvrufq64lvKm0vijOiLCuGn_hRWfGDFM_TOZigyc8cR-pQ/exec';
    const proxyUrl = 'https://corsproxy.io/?';
  
    let sessionValue = sessionStorage.getItem('user_session');
    let sessionValueGuid = sessionStorage.getItem('user_sessionGuid');
    
    // Verifica e define `user_session`
    if (!sessionValue) {
      sessionValue = `session_${new Date().getTime()}`;
      sessionStorage.setItem('user_session', sessionValue);
    }
    
    // Verifica e define `user_sessionGuid` (GUID)
    if (!sessionValueGuid) {
      sessionValueGuid = getUUID(); // Gera um GUID único
      sessionStorage.setItem('user_sessionGuid', sessionValueGuid); // Armazena o GUID no sessionStorage
    }
  
    const getGeoLocation = async () => {
      try {
        const response = await fetch('https://ipinfo.io/json');
        if (!response.ok) {
          throw new Error('Failed to fetch IP information');
        }
        const result = await response.json();
        if (result && result.country && result.region && result.city) {
          return `${result.country}, ${result.region}, ${result.city}`;
        } else {
          return 'Localização não encontrada';
        }
      } catch (error) {
        console.error('Erro ao obter geolocalização:', error);
        return 'Localização não encontrada';
      }
    };
  
    const getIPAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error('Erro ao obter o IP:', error);
        return 'IP Não Disponível';
      }
    };
  
    const geoIp = await getGeoLocation();
    const userIP = await getIPAddress();
  
    // Definir valores padrão internos
    const data = {
      Campaign: 'Pets',
      Session: sessionValue,
      Date: new Date().toISOString(),
      IP: userIP || 'N/A',
      Device: navigator.userAgent.includes('Mobile') ? 'Mobile' : 'Desktop',
      Geo_IP: geoIp,
      Name: name,
      Email: email,
      Phone: phone,
      Vat: vat,
      PostalCode: postalCode,
      Observations: observations,
      PetName: petname,
      PetAge: petage,
      PetType: pettype,
      Publisher: publisher,
      UUID: sessionValueGuid
    };
  
    try {
      // Enviar os dados para ambos os scripts simultaneamente usando Promise.all
      const responses = await Promise.all([
        fetch(proxyUrl + scriptUrl1, {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }),
        fetch(proxyUrl + scriptUrl2, {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      ]);
  
      console.log(`Sucesso ao enviar dados para ambos os scripts.`);
    } catch (error) {
      console.error(`Erro ao enviar dados: ${error}`);
    }
  };
  


      useEffect(() => {
        window.addEventListener('load', handleLoad, { once: true });
      
        return () => window.removeEventListener('load', handleLoad);
      }, []);
      
      const handleLoad = () => {
        sendDataToGoogleSheet({ fromwhere: "load", publisher: publishername });

        
      };
      
  return (
    <>
<div className="w-full flex flex-col lg:flex-row h-auto lg:h-[820px]">

    <div className={` w-full  flex flex-col justify-between bg-[url('../src/assets/top_pets.png')] bg-no-repeat bg-cover bg-center pt-4 lg:pt-12 pl-6 lg:pl-24 text-white`}>
    <div className='max-md:mb-12 max-md:flex max-md:flex-col max-md:items-center'>
    <img src={logo} alt='' className='max-md:w-[200px]' />
    <p className='font-bebasneuepro text-themered text-xl lg:text-3xl font-bold pt-2 max-md:text-center'>
        SEGURO DE SAÚDE ANIMAL
    </p>
</div>


    
<div
  className='mb-4 lg:mb-16 text-left lg:text-left'
  style={{
    marginBottom: '1rem', // Ajuste responsivo para LG
    textAlign: 'left',
  }}
>
  <h1
    className="font-bebasneuepro text-4xl lg:text-8xl font-bold mb-0 lg:mb-0 uppercase"
    style={{
      fontFamily: "'Bebas Neue Pro', sans-serif",
      fontSize: '2.25rem', // Ajuste de tamanho base
      fontWeight: 'bold',
      marginBottom: '0',
      textTransform: 'uppercase',
      lineHeight: '1.2',
    }}
  >
    Aproveite
  </h1>

  <p
    className='mb-6 font-bold text-4xl lg:text-4xl font-bebasneuepro uppercase'
    style={{
      marginBottom: '1.5rem',
      fontSize: '2.25rem',
      fontWeight: 'bold',
      fontFamily: "'Bebas Neue Pro', sans-serif",
      textTransform: 'uppercase',
    }}
  >
  </p>

  <div
    className='bg-themered text-white border border-themered px-1 py-1 text-xl lg:text-3xl uppercase text-center font-bebasneuepro inline-block'
    style={{
      backgroundColor: '#ff0000', // Substituir bg-themered
      color: '#ffffff',
      border: '1px solid #ff0000',
      padding: '0.25rem 0.25rem',
      fontSize: '1.25rem', // Ajuste responsivo
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'inline-block',
      margin: '0 auto', // Centralizado
    }}
  >
    70% de reembolso em despesas
    <span
      className="text-sm lg:text-base align-top"
      style={{
        fontSize: '0.875rem', // Ajuste responsivo
        verticalAlign: 'top',
      }}
    >
      (1)
    </span>
  </div>
</div>


    </div>
    </div>
    <div className='w-full  bg-themered lg:px-16 lg:pb-16'>
      <div className='text-center '>
      <img src={fromicon} alt='' className='max-md:hidden m-auto' />
      </div>
      
      {formstep === 1 && (
  <p className="text-white text-center font-bebasneuepro text-2xl lg:text-3xl mb-1 font-semibold mt-10 lg:mt-5 lg:mb-5">
    PEÇA JÁ A SUA SIMULAÇÃO DE UM SEGURO ANIMAL
  </p>
)}

{formstep === 2 && (
  <p className="text-white text-center font-bebasneuepro text-2xl lg:text-3xl mb-1 font-semibold mt-10 lg:mt-5 lg:mb-5">
     
    Simulação de Seguro Animal para o seu {formdata.pettype}
  </p>
)}
<div
  className={`bg-white rounded-xl mx-4 pt-2 px-4 lg:px-12 mb-3 ${formstep > 2 ? 'hidden' : 'block'}`}
  style={{ height: formdata.pettype ? '610px' : 'auto', paddingBottom: '20px' }} // Ajuste dinâmico da altura e padding-bottom
>

  <div className={` ${formstep == 1 ? 'block' :'hidden'}`}>
  <p className='font-bebasneuepro font-semibold text-center text-2xl lg:text-3xl py-2 uppercase lg:mb-2'>Qual é o seu animal de companhia?
  </p>

  <div className='flex gap-8 justify-center mb-2 font-bebasneuepro font-semibold text-[#777] text-md lg:text-2xl lg:px-8'>
  <button
  className={`w-full px-4 py-2 lg:py-3 flex justify-center items-center border-2 gap-4 rounded-xl uppercase transition-colors duration-300 ${
    formdata["pettype"] === "CÃO" ? "border-themered bg-[#f9c8cb]" : "border-[#eaeaea] bg-[#f7f5f5]"
  }`}
  onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f7e4e7")} // Efeito de hover
  onMouseOut={(e) => (e.currentTarget.style.backgroundColor = formdata["pettype"] === "CÃO" ? "#f9c8cb" : "#f7f5f5")} // Volta ao normal
  onClick={() => updatepettype("CÃO")}
>
  <img src={dog} className="w-[60px] lg:w-[80px]" alt="Cão" /> Cão
</button>

<button
  className={`w-full px-4 py-2 lg:py-3 flex justify-center items-center gap-4 border-2 rounded-xl uppercase transition-colors duration-300 ${
    formdata["pettype"] === "GATO" ? "border-themered bg-[#f9c8cb]" : "border-[#eaeaea] bg-[#f7f5f5]"
  }`}
  onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f7e4e7")} // Efeito de hover
  onMouseOut={(e) => (e.currentTarget.style.backgroundColor = formdata["pettype"] === "GATO" ? "#f9c8cb" : "#f7f5f5")} // Volta ao normal
  onClick={() => updatepettype("GATO")}
>
  <img src={cat} className="w-[60px] lg:w-[80px]" alt="Gato" /> Gato
</button>

              </div>

              {(!formdata["pettype"] || formdata["pettype"] === "") && (
                <div className="text-center font-bold py-4">

<div className="flex justify-center mt-4">
  <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-blue-500"></div>
</div>
<p className="text-xl mt-4">PARA RECEBER SIMULAÇÃO SELECIONE A SUA COMPANHIA</p> 
 


<div className="flex justify-center">
  <FontAwesomeIcon icon={faArrowCircleUp} size="2x" color="red" />
</div>
</div>
)}




{showAgeQuestion && (
                <>



<div className='px-5 relative mb-3'>
<input 
  type='text' 
  ref={petNameimp} 
  name="petname"
  value={formdata.petname}
  onChange={validateform}  
  className={`
    block w-full py-6 pl-14 pr-10 
    rounded-lg border-2 border-black 
    focus:border-black outline-none 
    font-bold 
    text-base      /* Fonte um pouco menor em dispositivos móveis */
    lg:text-xl     /* Aumenta um pouco em telas grandes */
    placeholder:font-bold 
    placeholder:text-red-500 
    placeholder:text-base  /* Placeholder também um pouco menor em mobile */
    lg:placeholder:text-xl  /* Placeholder aumenta em telas maiores */
    text-center
  `} 
  placeholder={`Escreva aqui o nome do ${formdata.pettype}`}  
  maxLength={100} 
/>



  <span className='absolute top-[1px] left-16 pl-1 text-[0.7em] transition-all duration-10 text-themered'></span>

  {/* Alterar o ícone dinamicamente com base no tipo de pet e ajustar o tamanho */}
  <img 
    src={formdata.pettype === 'CÃO' ? dog : formdata.pettype === 'GATO' ? cat : nameicon} 
    alt='' 
    className='absolute top-[50%] -translate-y-[50%] left-9 w-[30px] h-[30px]'  // Definir largura e altura menores
  />
</div>




                  <p className='font-bebasneuepro font-semibold text-center text-xl lg:text-2xl py-1 uppercase'>
                    Que idade tem o seu {formdata.pettype}?
                  </p>
                  <p className='text-center text-xs lg:text-md py-1'>Seguro válido para pets com idade igual ou inferior a 8 anos</p>

                  <p className='flex items-center justify-center text-center text-[#777] text-2xl lg:text-3xl my-4'>
                    {formdata["agerange"] || "Selecione a idade abaixo"}
                  </p>

                  <div className='py-4'>
  <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
    <span style={{ fontWeight: 'bold' }}>1 Mês</span> {/* Rótulo à esquerda em negrito */}
    <span style={{ fontWeight: 'bold' }}>8 Anos</span> {/* Rótulo à direita em negrito */}
  </div>
  <Box sx={{ width: '100%' }}>
    <Slider
      aria-label="age range"
      defaultValue={1}
      getAriaValueText={getpetage}
      step={1}
      marks
      min={1}
      max={6}
      onChange={handleSliderChange}
      sx={{
        '& .MuiSlider-thumb': {
          width: 32, // Aumenta o tamanho da bolinha (mola)
          height: 32,
        },
        '& .MuiSlider-track': {
          height: 12, // Aumenta a altura da barra preenchida
        },
        '& .MuiSlider-rail': {
          height: 12, // Aumenta a altura da barra não preenchida
        }
      }}
    />
  </Box>
</div>

<div style={{ marginBottom: '20px' }}>

<p className='text-xs lg:text-md text-[#212121]'>
  <strong
    onClick={() => setShowMoreText(!showMoreText)} // Toggle on click
    style={{ cursor: 'pointer' }} // Pointer style
  >
    Ver termos e Condições
  </strong>
  <br />
  {/* Conditionally render additional text */}
  {showMoreText && (
    <div>
      <span>
        Os dados pessoais por si acima facultados serão tratados <strong>para posterior contacto telefónico com a finalidade de realizar a simulação</strong> e para fazer o acompanhamento comercial da mesma, com fundamento no desenvolvimento de diligências pré-contratuais a pedido do titular e no interesse legítimo do desenvolvimento da atividade comercial da Fidelidade.
      </span>
      <p className='text-themered text-xs leading-1 py-4'>
        <a
          href='https://www.fidelidade.pt/PT/protecao-dados/Documents/Politica_de_Privacidade_e_Protecao_de_Dados_Pessoais%E2%80%93FIDELIDADE.pdf'
          className='underline text-themered'
          target='_blank'
          rel='noopener'
          
        >
          Consulte aqui, informação mais detalhada sobre proteção e tratamento dos seus dados pela Fidelidade.
        </a>
      </p>
      <br />
    </div>
  )}
</p>
</div>

          
                </>
              )}  

</div>
<div className={` ${formstep === 2 ? 'block' :'hidden'}`}>
<div className="flex flex-col items-center text-center lg:flex-row lg:justify-between">
  {/* Texto para o formulário */}
  <div className="flex items-center justify-center">
  <img
    src={formdata.pettype === 'CÃO' ? dog : cat}
    alt={formdata.pettype}
    className="w-24 h-24 mr-4"  // Espaço à direita da imagem
  />
<h2 className="text-3xl font-bebasneuepro">
    {formdata.petname}
  </h2>
  </div>

 

</div>


 <div className='px-5 relative mb-3'>
 <input 
    type='text' 
    ref={nomeinp} 
    name="name" 
    value={formdata.name} 
    onChange={validateform}  
    className={`block w-full py-4 pl-12 pr-8 rounded-lg border-2 ${
      formdata.name ? 'border-green-500' : 'border-red-500'
    } focus:border-blue-500 outline-none font-bold text-lg placeholder:text-gray-500`} 
    placeholder='Insira o seu nome' 
    maxLength={100} 
  />
  <span className='absolute top-[50%] left-9 -translate-y-[50%]'>
    <img src={nameicon} alt='' className='w-[24px]' />
  </span>
 </div>

 <div className='px-5 relative mb-3'>
 
 <input 
    type='email' 
    ref={emailinp} 
    name="email" 
    value={formdata.email} 
    onChange={validateform}  
    className={`block w-full py-4 pl-12 pr-8 rounded-lg border-2 ${
      checkemail(emailinp) ? 'border-green-500' : 'border-red-500'
    } focus:border-blue-500 outline-none font-bold text-lg placeholder:text-gray-500`} 
    placeholder='Insira o seu e-mail' 
    maxLength={100} 
  />
 



 <span id="emaillbl" className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <img src={emailicon} alt='' className='absolute top-[50%] -translate-y-[50%] left-9'/>
 </div>

 <div className='px-5 relative mb-3'>
 <input 
    type='number' 
    inputMode="numeric" 
    ref={nifinp} 
    name="nif" 
    value={formdata.nif} 
    onChange={validateform}  
    className={`block w-full py-4 pl-12 pr-8 rounded-lg border-2 ${
      formdata.nif.length === 9 ? 'border-green-500' : 'border-red-500'
    } focus:border-blue-500 outline-none font-bold text-lg placeholder:text-gray-500`} 
    placeholder='Insira o seu NIF' 
    maxLength={9} 
  />
 <span id="niflbl" className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <img src={nificon} alt='' className='absolute top-[50%] -translate-y-[50%] left-9'/>
 </div>

<div className='flex flex-col lg:flex-row mb-1'>
<div className='w-full px-5 relative mb-3'>
<input 
      type='text' 
      inputMode="numeric" 
      ref={zipinp} 
      name="zip" 
      value={formdata.zip} 
      onChange={validatezip}  
      className={`block w-full py-4 pl-12 pr-8 rounded-lg border-2 ${
        formdata.zip.length === 8 ? 'border-green-500' : 'border-red-500'
      } focus:border-blue-500 outline-none font-bold text-lg placeholder:text-gray-500`} 
      placeholder='Código Postal' 
      maxLength={8} 
    />
 <span id="ziplbl" className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <img src={zipicon} alt='' className='absolute top-[50%] -translate-y-[50%] left-9'/>
 </div>

 <div className='w-full px-5 relative mb-3'>
 <input 
      type='number' 
      inputMode="numeric" 
      name="phone" 
      ref={mobileinp} 
      value={formdata.phone} 
      onChange={validateform}  
      className={`block w-full py-4 pl-12 pr-8 rounded-lg border-2 ${
        formdata.phone.length === 9 ? 'border-green-500' : 'border-red-500'
      } focus:border-blue-500 outline-none font-bold text-lg placeholder:text-gray-500`} 
      placeholder='Nº Telemóvel' 
      maxLength={9} 
    />
 <span id="mobilelbl" className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <img src={phoneicon2} alt='' className='absolute top-[50%] -translate-y-[50%] left-9'/>
 </div>

  </div>

 </div>


 {errorMessage && (
  <div className="text-red-500 text-center mb-4">
    {errorMessage}
  </div>
)}
{formdata.pettype && (

  
  
  
  
  <div className='flex justify-between '>


    
<div className='' ><button onClick={()=>setFormstep(1)} className={` bg-[#9fbe80] hover:bg-[#86ad5e] rounded-full w-[60px] h-[60px] flex justify-center items-center  ${formstep === 1 ? 'hidden lg:invisible' : ''} `}><img src={arrowcta} alt="" className='w-[15px] rotate-180' /></button></div>


<div className="flex justify-center pb-4">
  <button
    onClick={() => {
      if (isFormValid()) {
        setFormstep(2);
        submitform();
      }
    }}
    className="flex items-center justify-between w-[250px] lg:w-[500px] bg-green-600 hover:bg-green-700 text-center rounded-full px-6 py-4 text-white font-bebasneuepro font-bold text-2xl shadow-lg transform transition-transform duration-300 hover:scale-105"
  >
    <span className="flex-grow font-bold">{formstep === 1 ? 'PRÓXIMO PASSO' : 'PEDIR SIMULAÇÃO'}</span>
    <img src={arrowcta} alt="Seta" className="w-[20px] ml-3" />
  </button>
</div>

<div className=''></div>
</div>
    )}


 



</div>

<div className={`max-w-[90%] lg:max-w-[95%] ${formstep == 3? 'block' : 'hidden'} text-white text-left font-bebasneuepro text-2xl lg:text-3xl p-[30px]`}><p className={`text-white text-left font-bebasneuepro text-2xl lg:text-2xl mb-3 font-semibold mt-10 lg:mt-5 lg:mb-5 `}>
Olá  {formdata["name"]}!</p>
Obrigado pelo seu pedido de simulação.<br />
Em breve, a nossa equipa entrará em contacto consigo para lhe apresentar a nossa melhor proposta.<br />
Enquanto isso, pode explorar mais sobre os nossos produtos e serviços no nosso site:  <br></br>
<a href='https://www.fixo.pt/fixo/?utm_source=referral&utm_medium=afiliacao&utm_campaign=fid_pontual_fixo_referral_afiliacao_lp_pets_typ&utm_content=produto' target='_blank' className="text-black font-bold">www.fixo.pt</a><br />
Obrigado<br /> <br></br>
<strong>Fidelidade</strong>
</div>
<div className={`max-w-[90%] lg:max-w-[95%] ${formstep == 4 ? 'block' : 'hidden'} text-white text-left font-bebasneuepro text-2xl lg:text-3xl p-[30px]`}>
<p className={`text-white text-left  font-bebasneuepro text-2xl lg:text-2xl mb-3 font-semibold mt-10 lg:mt-5 lg:mb-5 `}>

  Olá  {formdata["name"]}!</p>
Obrigado pelo seu pedido de simulação.<br />
Em breve, a nossa equipa entrará em contacto consigo para lhe apresentar a nossa melhor proposta.<br />
Enquanto isso, pode explorar mais sobre os nossos produtos e serviços no nosso site:  <br></br>
<a href='https://www.fixo.pt/fixo/?utm_source=referral&utm_medium=afiliacao&utm_campaign=fid_pontual_fixo_referral_afiliacao_lp_pets_typ&utm_content=produto' target='_blank' className="text-black font-bold">www.fixo.pt</a><br />
Obrigado<br /> <br></br>
<strong>Fidelidade</strong>
 
</div>

<div className={`max-w-[90%] lg:max-w-[95%] ${formstep == 5 ? 'block' : 'hidden'} text-white text-left font-bebasneuepro text-2xl lg:text-3xl p-[30px]`}>
<p className={`text-white text-left  font-bebasneuepro text-2xl lg:text-2xl mb-3 font-semibold mt-10 lg:mt-5 lg:mb-5 `}>
Obrigado  {formdata["name"]}!</p>
Verificamos que já tinha pedido um contacto anteriormente.<br />
A nossa equipa entrará em contacto consigo o mais breve possível para ajudar a esclarecer.<br />
Enquanto isso, pode explorar mais sobre os nossos produtos e serviços no nosso site:  <br></br>
<a href='https://www.fixo.pt/fixo/?utm_source=referral&utm_medium=afiliacao&utm_campaign=fid_pontual_fixo_referral_afiliacao_lp_pets_typ&utm_content=produto' target='_blank' className="text-black font-bold">www.fixo.pt</a><br />
Obrigado<br /> <br></br>
<strong>Fidelidade</strong>
</div>

<div className={`App ${formstep > 2 ? 'block' : 'hidden'} w-[90%] mx-auto bg-white rounded-lg text-center`} style={{ padding: '40px', marginBottom: '20px' }}>
 



<p className="text-black font-bold text-xl">
    Dentro de {countdown} segundos será redirecionado para{' '}
    <a href={targetUrl} className="text-blue-400 underline">www.fixo.pt</a>
  </p>
</div>



      </div>
  


 
    <Fidelidadepet />
    <Footer />
    </>
   
  );
}

export default App;
