import React from 'react'
import checkicon from '../src/assets/1.svg';
import hearticon from '../src/assets/2.svg';
import phoneicon from '../src/assets/3.svg';
import plusicon from '../src/assets/4.svg';
import locationicon from '../src/assets/5.svg';
import pencilicon from '../src/assets/6.svg';
import arrowcta from '../src/assets/arrow-cta.svg';
import bannerpets from '../src/assets/banner-pets.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw } from '@fortawesome/free-solid-svg-icons';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const scrollToTop = () => {
  scroll.scrollToTop();
};


function Fidelidadepet() {
  return (
    <>
    <div className='flex flex-col lg:flex-row pt-12 lg:pt-32'>
      <div className='w-full'>
      <div className='block pl-8 lg:pl-20  lg:mb-48 lg:pb-80'> 
      
      <h2 className='font-bebasneuepro text-themered text-3xl lg:text-6xl font-bold mb-1 lg:mb-2 text-center lg:text-left'>FIDELIDADE PETS</h2>
      <p className='font-bebasneuepro uppercase text-[#212121] text-2xl lg:text-4xl font-bold mb-10 lg:mb-16 text-center lg:text-left'>Ajudamos a cuidar do seu melhor amigo</p>
          
          <div className='flex w-full flex-col lg:flex-row  flex-wrap font-bebasneuepro text-md md:text-lg lg:text-2xl uppercase '>
      <div className='w-full lg:w-1/2 flex gap-3 items-center mb-3 lg:mb-12'>
      <img src={checkicon} alt='' className='max-w-[45px] lg:max-w-full' />
      <span>Check-up sem <br className='hidden lg:block' />
      custos adicionais<sup>(2)</sup></span>
      </div>
      <div className='w-full lg:w-1/2 flex gap-3 items-center mb-3 lg:mb-12'>
      <img src={hearticon} alt='' className='max-w-[45px] lg:max-w-full' />
      Sem idade limite <br className='hidden lg:block' />
      de permanência
      </div>
      <div className='w-full lg:w-1/2 flex gap-3 items-center mb-3 lg:mb-12'>
      <img src={phoneicon} alt='' className='max-w-[45px] lg:max-w-full' />
      consultas por videochamada <br className='hidden lg:block' />
      com médicos veterinários
      </div>
      <div className='w-full lg:w-1/2 flex gap-3 items-center mb-3 lg:mb-12'>
      <img src={plusicon} alt='' className='max-w-[45px] lg:max-w-full' />
      Esterilização <br className='hidden lg:block' />
      e Vacinas
      </div>
      <div className='w-full lg:w-1/2 flex gap-3 items-center mb-3 lg:mb-12'>
      <img src={locationicon} alt='' className='max-w-[45px] lg:max-w-full' />
      Pode continuar <br className='hidden lg:block' />
      no mesmo veterinário
      </div>
      <div className='w-full lg:w-1/2 flex gap-3 items-center mb-3 lg:mb-12'>
      <img src={pencilicon} alt='' className='max-w-[45px] lg:max-w-full' />
      Participação <br className='hidden lg:block' />
      de sinistros online
      </div>
          </div>
          </div>
      </div>
      <div className='w-full  min-h-[400px] bg-[url(../src/assets/banner-pets.png)] bg-no-repeat  bg-[length:100%_auto]'></div>
    </div>
    <div className='flex justify-center pb-4 mt-4 lg:mt-12'>
    <button
      onClick={scrollToTop}
      className='flex items-center justify-center w-[250px] lg:w-[350px] bg-[#E31A10] hover:bg-[#C4160C] text-center rounded-full px-4 py-3 text-white font-bold font-sans text-2xl transition-transform transform hover:scale-105 shadow-lg'
      style={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)' }}
    >
      {/* Ícone de Patinha de Pet */}
      <FontAwesomeIcon icon={faPaw} size='lg' style={{ marginRight: '10px' }} />
      <span>PEDIR JÁ SIMULAÇÃO</span>
    </button>
  </div><br></br><br></br>
    <div className='text-center uppercase bg-[#f4f4f4] px-10 py-10 lg:py-16 mb-12'>
    <h2 className='font-bebasneuepro text-[#212121] text-3xl lg:text-5xl font-bold mb-5 lg:mb-2'>Aproveite <span className='text-themered'>70% de reembolso</span> em despesas<sup>(1)</sup></h2>
      <p className='font-bebasneuepro uppercase text-[#212121] text-xl lg:text-3xl tracking-wide  mb-5 lg:mb-16'>SAIBA QUAL O <strong>SEGURO MAIS ADEQUADO</strong> PARA O SEU ANIMAL DE COMPANHIA!      </p>
     
 
      <div class="max-w-screen-xl mx-auto p-6">
    <h1 class="text-3xl font-bold text-center text-gray-800 mb-10">
      Vantagens exclusivas de ser cliente Fidelidade Pets
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
     
      <div class="bg-white p-6 rounded-lg shadow-md text-center">
        <div class="text-red-500 mb-4">
        <FontAwesomeIcon icon={faCheckCircle} size="2x" />
        </div>
        <h2 class="text-xl font-bold text-gray-800 mb-4">
          Extensa Rede de Parceiros e Prestadores
        </h2>
        <p class="text-gray-600 text-sm">
          Desfrute de descontos em +300 parceiros para os mimar com banhos, tosquias, ração e muito mais. Conte ainda com o atendimento especializado de +200 prestadores em todo o país.
        </p>
      </div>
 
      <div class="bg-white p-6 rounded-lg shadow-md text-center">
        <div class="text-red-500 mb-4">
        <FontAwesomeIcon icon={faCheckCircle} size="2x" />
        </div>
        <h2 class="text-xl font-bold text-gray-800 mb-4">
          Recompensas por cuidar
        </h2>
        <p class="text-gray-600 text-sm">
          Recompensamos donos e patudos por utilizarem o seguro Fidelidade Pets. Receba prémios num conjunto de marcas parceiras como Pingo Doce, IKEA, Veterinário sobre Rodas e muitos mais.
        </p>
      </div>
 
      <div class="bg-white p-6 rounded-lg shadow-md text-center">
        <div class="text-red-500 mb-4">
        <FontAwesomeIcon icon={faCheckCircle} size="2x" />
        </div>
        <h2 class="text-xl font-bold text-gray-800 mb-4">
          Gestão Online 24/7
        </h2>
        <p class="text-gray-600 text-sm">
          Como os azares não escolhem horas, estamos disponíveis 24/7. Faça a gestão do seguro online, desde pedidos de reembolso, marcações de teleconsultas a pedidos de assistência.
        </p>
      </div>

   
      <div class="bg-white p-6 rounded-lg shadow-md text-center">
        <div class="text-red-500 mb-4">
        <FontAwesomeIcon icon={faCheckCircle} size="2x" />
        </div>
        <h2 class="text-xl font-bold text-gray-800 mb-4">
          Os Seus Animais, a Nossa Família
        </h2>
        <p class="text-gray-600 text-sm">
          Sabemos o quão importante são os nossos animais de companhia e por isso mesmo temos uma área dedicada. No MyPets pode encontrar dicas sobre a saúde, bem-estar e comportamento do seu animal.
        </p>
      </div>
    </div>
  </div>

  <div className='flex justify-center pb-4 mt-4 lg:mt-12'>
    <button
      onClick={scrollToTop}
      className='flex items-center justify-center w-[250px] lg:w-[350px] bg-[#E31A10] hover:bg-[#C4160C] text-center rounded-full px-4 py-3 text-white font-bold font-sans text-2xl transition-transform transform hover:scale-105 shadow-lg'
      style={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)' }}
    >
      {/* Ícone de Patinha de Pet */}
      <FontAwesomeIcon icon={faPaw} size='lg' style={{ marginRight: '10px' }} />
      <span>PEDIR JÁ SIMULAÇÃO</span>
    </button>
  </div>
 
     
     </div>
    </>
    
  )
}

export default Fidelidadepet